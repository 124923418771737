import * as React from 'react';
import { DozukiFooter, DozukiFooterProps } from '@dozuki/web-js/components';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { _js } from '@ifixit/localize';

type DozukiFooterComponentProps = Pick<
   DozukiFooterProps,
   'siteTitle' | 'siteDomain' | 'footerLinks'
>;

function DozukiFooterComponent(props: DozukiFooterComponentProps) {
   return <DozukiFooter {...props} _js={_js} />;
}

initializeChakraComponent('DozukiFooterComponent', DozukiFooterComponent);
